<template>
  <section class="section is-main-section">
    <div class="mb-4">
      <b-button
        icon-left="chevron-left"
        type="is-dark"
        outlined
        @click="willBackPaymentWork"
        >計算結果に戻る</b-button
      >
    </div>

    <Tiles :maxPerRow="4">
      <CardComponent class="tile is-child is-card-widget">
        <div class="level is-mobile">
          <div class="level-item">
            <div class="is-widget-label">
              <h3 class="subtitle is-spaced r">#{{ code }}</h3>
              <h1 class="title">{{ name }}</h1>
            </div>
          </div>
          <div class="level-item has-widget-icon">
            <div class="is-widget-icon">
              <b-icon icon="account" size="is-large" type="is-success" />
            </div>
          </div>
        </div>
      </CardComponent>
      <CardWidget
        class="tile is-child"
        type="is-dark"
        icon="blank"
        :number="totalAmount"
        suffix="円"
        label="小計"
      />
      <CardWidget
        class="tile is-child"
        type="is-dark"
        icon="blank"
        :number="withholdingAmount"
        suffix="円"
        label="源泉徴収税額"
      />
      <CardWidget
        class="tile is-child"
        type="is-dark"
        icon="blank"
        :number="taxedAmount"
        suffix="円"
        label="差引"
      />
    </Tiles>
    <CardComponent class="has-table" title="実績明細">
      <b-table
        :loading="isLoading"
        :paginated="paginated"
        :per-page="perPage"
        :data="tPaymentRows"
        style="font-size: 0.9rem"
      >
        <b-table-column label="エリア名" v-slot="{ row }">
          <span>{{ row.areaName }}</span>
        </b-table-column>
        <b-table-column label="施設名" v-slot="{ row }">
          <span>{{ row.facility.label }}</span>
        </b-table-column>
        <b-table-column label="プログラム" v-slot="{ row }">
          <span>{{ row.programName }}</span>
        </b-table-column>
        <b-table-column label="日付" v-slot="{ row }">
          <span>{{ row.targetDate.format('YYYY/MM/DD') }}</span>
        </b-table-column>
        <b-table-column label="出勤時間" v-slot="{ row }" numeric>
          <span v-if="row.instillMinutes > 0">{{ row.minutes }}(+{{row.instillMinutes}})分</span>
          <span v-else>{{ row.minutes }}分</span>
        </b-table-column>
        <b-table-column label="カテゴリ" v-slot="{ row }">
          <span>{{ row.categoryName }}</span>
        </b-table-column>
        <b-table-column label="単価" v-slot="{ row }" numeric>
          <span>{{ row.unitAmount.toLocaleString() }}</span>
        </b-table-column>
        <b-table-column label="時間給" v-slot="{ row }" numeric>
          <span>{{ row.baseAmount.toLocaleString() }}</span>
        </b-table-column>
        <b-table-column label="" v-slot="{ row }">
          <span>{{ row.instillLabels }}</span>
        </b-table-column>
        <b-table-column label="各種手当" v-slot="{ row }" numeric>
          <span>{{ row.instillAmount.toLocaleString() }}</span>
        </b-table-column>
        <b-table-column label="固定額" v-slot="{ row }" numeric>
          <span>{{ row.fixedAmount.toLocaleString() }}</span>
        </b-table-column>
        <b-table-column label="合計" v-slot="{ row }" width="6em" numeric>
          <span>{{ row.amount.toLocaleString() }}円</span>
        </b-table-column>
        <section class="section" slot="empty">
          <div class="content has-text-grey has-text-centered">
            <template v-if="isLoading">
              <p>
                <b-icon icon="dots-horizontal" size="is-large" />
              </p>
              <p>Fetching data...</p>
            </template>
            <template v-else>
              <p>
                <b-icon icon="emoticon-sad" size="is-large" />
              </p>
              <p>Nothing's here&hellip;</p>
            </template>
          </div>
        </section>
      </b-table>
    </CardComponent>
    <div class="columns" v-if="tPaymentBonuses.length > 0">
      <div class="column is-half-fullhd">
        <CardComponent class="has-table" title="その他給">
          <b-table :loading="isLoading" :data="tPaymentBonuses">
            <b-table-column label="No" v-slot="{ index }" width="4em">
              <span>{{ index + 1 }}</span>
            </b-table-column>
            <b-table-column label="エリア" v-slot="{ row }" width="7em">
              <span>{{ row.areaName }}</span>
            </b-table-column>
            <b-table-column
              label="その他給"
              v-slot="{ row }"
              numeric
              width="7em"
            >
              <span>{{ row.amount.toLocaleString() }}円</span>
            </b-table-column>
            <b-table-column label="備考" v-slot="{ row }">
              <span>{{ row.note }}</span>
            </b-table-column>
            <section class="section" slot="empty">
              <div class="content has-text-grey has-text-centered">
                <template v-if="isLoading">
                  <p>
                    <b-icon icon="dots-horizontal" size="is-large" />
                  </p>
                  <p>Fetching data...</p>
                </template>
                <template v-else>
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large" />
                  </p>
                  <p>Nothing's here&hellip;</p>
                </template>
              </div>
            </section>
          </b-table>
        </CardComponent>
      </div>
    </div>
  </section>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import { ApiPaymentInstructor } from '@/api'
import CardWidget from '@/components/CardWidget.vue'
import Tiles from '@/components/Tiles.vue'

export default {
  mixins: [BaseMixin],
  components: { CardComponent, CardWidget, Tiles },
  props: {
    pathPaymentYm: String,
    pathInstructorId: String
  },
  data () {
    return {
      perPage: 40,
      code: '',
      name: '',
      totalAmount: 0,
      withholdingAmount: 0,
      tPaymentRows: [],
      tPaymentBonuses: [],
    }
  },
  computed: {
    instructorId () {
      return Number(this.pathInstructorId) || 0
    },
    paginated () {
      return this.tPaymentRows.length > this.perPage
    },
    taxedAmount () {
      return this.totalAmount - this.withholdingAmount
    }
  },
  methods: {
    willBackPaymentWork () {
      this.$router.replace({ name: 'PaymentWork' })
    },
  },
  created () {
    this.isLoading = true
  },
  mounted () {
    ApiPaymentInstructor.readPaymentInstructor(this.pathPaymentYm, this.instructorId).then(({ code, name, totalAmount, withholdingAmount, tPaymentRows, tPaymentBonuses }) => {
      this.code = code
      this.name = name
      this.totalAmount = totalAmount
      this.withholdingAmount = withholdingAmount
      this.tPaymentRows = tPaymentRows
      this.tPaymentBonuses = tPaymentBonuses
      this.isLoading = false
    }).catch(this.handleError)
  },
}
</script>
