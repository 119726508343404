var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section is-main-section"},[_c('div',{staticClass:"mb-4"},[_c('b-button',{attrs:{"icon-left":"chevron-left","type":"is-dark","outlined":""},on:{"click":_vm.willBackPaymentWork}},[_vm._v("計算結果に戻る")])],1),_c('Tiles',{attrs:{"maxPerRow":4}},[_c('CardComponent',{staticClass:"tile is-child is-card-widget"},[_c('div',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-item"},[_c('div',{staticClass:"is-widget-label"},[_c('h3',{staticClass:"subtitle is-spaced r"},[_vm._v("#"+_vm._s(_vm.code))]),_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.name))])])]),_c('div',{staticClass:"level-item has-widget-icon"},[_c('div',{staticClass:"is-widget-icon"},[_c('b-icon',{attrs:{"icon":"account","size":"is-large","type":"is-success"}})],1)])])]),_c('CardWidget',{staticClass:"tile is-child",attrs:{"type":"is-dark","icon":"blank","number":_vm.totalAmount,"suffix":"円","label":"小計"}}),_c('CardWidget',{staticClass:"tile is-child",attrs:{"type":"is-dark","icon":"blank","number":_vm.withholdingAmount,"suffix":"円","label":"源泉徴収税額"}}),_c('CardWidget',{staticClass:"tile is-child",attrs:{"type":"is-dark","icon":"blank","number":_vm.taxedAmount,"suffix":"円","label":"差引"}})],1),_c('CardComponent',{staticClass:"has-table",attrs:{"title":"実績明細"}},[_c('b-table',{staticStyle:{"font-size":"0.9rem"},attrs:{"loading":_vm.isLoading,"paginated":_vm.paginated,"per-page":_vm.perPage,"data":_vm.tPaymentRows}},[_c('b-table-column',{attrs:{"label":"エリア名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.areaName))])]}}])}),_c('b-table-column',{attrs:{"label":"施設名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.facility.label))])]}}])}),_c('b-table-column',{attrs:{"label":"プログラム"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.programName))])]}}])}),_c('b-table-column',{attrs:{"label":"日付"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.targetDate.format('YYYY/MM/DD')))])]}}])}),_c('b-table-column',{attrs:{"label":"出勤時間","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.instillMinutes > 0)?_c('span',[_vm._v(_vm._s(row.minutes)+"(+"+_vm._s(row.instillMinutes)+")分")]):_c('span',[_vm._v(_vm._s(row.minutes)+"分")])]}}])}),_c('b-table-column',{attrs:{"label":"カテゴリ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.categoryName))])]}}])}),_c('b-table-column',{attrs:{"label":"単価","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.unitAmount.toLocaleString()))])]}}])}),_c('b-table-column',{attrs:{"label":"時間給","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.baseAmount.toLocaleString()))])]}}])}),_c('b-table-column',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.instillLabels))])]}}])}),_c('b-table-column',{attrs:{"label":"各種手当","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.instillAmount.toLocaleString()))])]}}])}),_c('b-table-column',{attrs:{"label":"固定額","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.fixedAmount.toLocaleString()))])]}}])}),_c('b-table-column',{attrs:{"label":"合計","width":"6em","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.amount.toLocaleString())+"円")])]}}])}),_c('section',{staticClass:"section",attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[(_vm.isLoading)?[_c('p',[_c('b-icon',{attrs:{"icon":"dots-horizontal","size":"is-large"}})],1),_c('p',[_vm._v("Fetching data...")])]:[_c('p',[_c('b-icon',{attrs:{"icon":"emoticon-sad","size":"is-large"}})],1),_c('p',[_vm._v("Nothing's here…")])]],2)])],1)],1),(_vm.tPaymentBonuses.length > 0)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half-fullhd"},[_c('CardComponent',{staticClass:"has-table",attrs:{"title":"その他給"}},[_c('b-table',{attrs:{"loading":_vm.isLoading,"data":_vm.tPaymentBonuses}},[_c('b-table-column',{attrs:{"label":"No","width":"4em"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1))])]}}],null,false,2733264880)}),_c('b-table-column',{attrs:{"label":"エリア","width":"7em"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.areaName))])]}}],null,false,2208548436)}),_c('b-table-column',{attrs:{"label":"その他給","numeric":"","width":"7em"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.amount.toLocaleString())+"円")])]}}],null,false,255814476)}),_c('b-table-column',{attrs:{"label":"備考"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.note))])]}}],null,false,541981332)}),_c('section',{staticClass:"section",attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[(_vm.isLoading)?[_c('p',[_c('b-icon',{attrs:{"icon":"dots-horizontal","size":"is-large"}})],1),_c('p',[_vm._v("Fetching data...")])]:[_c('p',[_c('b-icon',{attrs:{"icon":"emoticon-sad","size":"is-large"}})],1),_c('p',[_vm._v("Nothing's here…")])]],2)])],1)],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }